import DataLayerScope from 'swp-components/lib/components/common/data-layer-scope'
import Footer from 'swp-components/lib/components/footer'
import Section from 'swp-components/lib/components/section'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import BottomBanner from '@/components/bottom-banner'
import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import DeviceShelf from '@/components/device-shelf'
import Header from '@/components/header'
import HighlightBanner from '@/components/highlight-banner'
import InternetPackage from '@/components/internet-package'
import MobilePackage from '@/components/mobile-package'
import Newrelic from '@/components/newrelic-agent'
import SectionHeading from '@/components/section-heading'
import Seo from '@/components/seo'
import ServiceBanner from '@/components/service-banner'
import ShortcutMenu from '@/components/shortcut-menu'
import TopBanner from '@/components/top-banner'
import useHomePageDataLayer from '@/hooks/data-layers/use-home-page'
import useInternetPackage from '@/hooks/use-internet-package'
import useMobilePackage from '@/hooks/use-mobile-package'
import strapi from '@/strapi'

const HomePage = ({ serverData }) => {
  let [
    seo,
    headerScript,
    pageHeaders,
    brandHeaders,
    productServices,
    topBanners,
    shortcutMenus,
    productServiceBanners,
    allPostpaidPackages,
    allPrepaidPackages,
    allExtraPackages,
    viewAllManagement,
    highlightBanners,
    deviceManagements,
    iotManagements,
    allTolPackages,
    tolContent,
    bottomBanners,
    footerHeaders,
    chatShop,
    contactWithSaleContent,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)

  let mobilePackages = useMobilePackage('all', {
    allPostpaidPackages,
    allPrepaidPackages,
    allExtraPackages,
    viewAllManagement,
  })

  let internetPackages = useInternetPackage('all', {
    allTolPackages,
    viewAllManagement,
  })

  let { handleMessage, createScopeProps } = useHomePageDataLayer()
  const changeDiscountToPercent = arrObj => {
    return arrObj.map(obj => {
      obj['discountPercent'] = Math.round(obj['discount'])
      obj['discount'] = 0
      return obj
    })
  }

  const deviceManagementsToDiscountPercent =
    changeDiscountToPercent(deviceManagements)
  const iotManagementsToDiscountPercent =
    changeDiscountToPercent(iotManagements)

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={seo.mainSeo} script={headerScript.script} />
        <DataLayerScope {...createScopeProps('Header')}>
          <Header items={brandHeaders} services={productServices} />
        </DataLayerScope>
        {topBanners.length > 0 && (
          <DataLayerScope {...createScopeProps('TopBanner', { topBanners })}>
            <TopBanner banners={topBanners} />
          </DataLayerScope>
        )}
        {shortcutMenus.length > 0 && (
          <DataLayerScope {...createScopeProps('ShortcutMenu')}>
            <ShortcutMenu className="my-4" items={shortcutMenus} />
          </DataLayerScope>
        )}
        {pageHeaders.landingPage.shortcutMenuHeader &&
          productServiceBanners.length > 0 && (
            <div className="mb-5">
              <>
                <SectionHeading variant="button-line-through" tag="h1">
                  {pageHeaders.landingPage.shortcutMenuHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" level="2">
                  {pageHeaders.landingPage.shortcutMenuHeader.detail}
                </SectionHeading>
              </>

              <DataLayerScope {...createScopeProps('ServiceBanner')}>
                <ServiceBanner banners={productServiceBanners} />
              </DataLayerScope>
            </div>
          )}
        {pageHeaders.landingPage.packageHeader && mobilePackages.length > 0 && (
          <div className="my-5">
            <>
              <SectionHeading variant="underline" tag="h2">
                {pageHeaders.landingPage.packageHeader.name}
              </SectionHeading>
              <SectionHeading className="mt-3 mb-4" level="2">
                {pageHeaders.landingPage.packageHeader.detail}
              </SectionHeading>
            </>

            <DataLayerScope
              {...createScopeProps('MobilePackage', {
                mobilePackages,
                header: pageHeaders.landingPage.packageHeader,
              })}
            >
              <MobilePackage packages={mobilePackages} />
            </DataLayerScope>
          </div>
        )}
        {highlightBanners.length > 0 && (
          <DataLayerScope
            {...createScopeProps('HighlightBanner', { highlightBanners })}
          >
            <HighlightBanner
              className="my-5"
              columns={2}
              banners={highlightBanners}
            />
          </DataLayerScope>
        )}
        {((pageHeaders.landingPage.deviceHeader &&
          deviceManagementsToDiscountPercent.length > 0) ||
          (pageHeaders.landingPage.iotHeader && iotManagements.length > 0)) && (
          <Section.Background className="py-5" circle>
            {pageHeaders.landingPage.deviceHeader &&
              deviceManagementsToDiscountPercent.length > 0 && (
                <div>
                  <>
                    <SectionHeading variant="underline" tag="h2">
                      {pageHeaders.landingPage.deviceHeader.name}
                    </SectionHeading>
                    <SectionHeading className="mt-3 mb-4" level="2">
                      {pageHeaders.landingPage.deviceHeader.detail}
                    </SectionHeading>
                  </>

                  <DataLayerScope
                    {...createScopeProps('DeviceShelf', {
                      deviceManagements: deviceManagementsToDiscountPercent,
                      header: pageHeaders.landingPage.deviceHeader,
                    })}
                  >
                    <DeviceShelf
                      devices={deviceManagementsToDiscountPercent}
                      tagDiscount="percent"
                      viewAll={viewAllManagement.deviceViewAll}
                    />
                  </DataLayerScope>
                </div>
              )}
            {pageHeaders.landingPage.iotHeader &&
              iotManagementsToDiscountPercent.length > 0 && (
                <div className="mt-5">
                  <>
                    <SectionHeading variant="underline" tag="h2">
                      {pageHeaders.landingPage.iotHeader.name}
                    </SectionHeading>
                    <SectionHeading className="mt-3 mb-4" level="2">
                      {pageHeaders.landingPage.iotHeader.detail}
                    </SectionHeading>
                  </>

                  <DataLayerScope
                    {...createScopeProps('IotShelf', {
                      iotManagements: iotManagementsToDiscountPercent,
                      header: pageHeaders.landingPage.iotHeader,
                    })}
                  >
                    <DeviceShelf
                      devices={iotManagementsToDiscountPercent}
                      tagDiscount="percent"
                      viewAll={viewAllManagement.iotViewAll}
                    />
                  </DataLayerScope>
                </div>
              )}
          </Section.Background>
        )}
        {pageHeaders.landingPage.tolHeader && internetPackages.length > 0 && (
          <div className="my-5">
            <>
              <SectionHeading variant="underline" tag="h2">
                {pageHeaders.landingPage.tolHeader.name}
              </SectionHeading>
              <SectionHeading className="mt-3 mb-4" level="2">
                {pageHeaders.landingPage.tolHeader.detail}
              </SectionHeading>
            </>

            <DataLayerScope
              {...createScopeProps('InternetPackage', {
                internetPackages,
                header: pageHeaders.landingPage.tolHeader,
              })}
            >
              <InternetPackage
                packages={internetPackages}
                content={tolContent}
                contactWithSaleContent={contactWithSaleContent}
              />
            </DataLayerScope>
          </div>
        )}
        {bottomBanners.length > 0 && (
          <DataLayerScope {...createScopeProps('BottomBanner')}>
            <BottomBanner banners={bottomBanners} />
          </DataLayerScope>
        )}
        {footerHeaders && (
          <DataLayerScope {...createScopeProps('Footer')}>
            <Footer className="pb-4" items={footerHeaders} />
          </DataLayerScope>
        )}
        {chatShop && (
          <DataLayerScope {...createScopeProps('ChatShop')}>
            <ChatShop
              content={chatShop}
              mariPlatform={ serverData.locale == 'th' ? "mari_trueh" : "truecorp" }
              mariConfig={mariConfig}
            />
          </DataLayerScope>
        )}
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default HomePage

export const getServerData = async ({ pageContext }) => {
  let contents = await strapi.getContents(
    [
      'seo',
      'header-script',
      'header-page',
      'brand-headers',
      'product-services',
      'top-banners',
      'shortcut-menus',
      'product-service-banners',
      'tmh-postpaid-all-packages',
      'tmh-prepaid-all-packages',
      'tmh-all-extra-packages',
      'view-all-management',
      'tmh-package-highlight-banners',
      'device-managements?category=device',
      'device-managements?category=iot',
      'tol-all-packages',
      'tol-package-box',
      'bottom-banners?page_group.slug=main',
      'footer-headers',
      'chat-and-shop',
      'tol-chat-with-sale-staff',
      'mari-chat-and-shop-config',
    ],
    pageContext.locale
  )

  return {
    props: { contents, locale: pageContext.locale },
  }
}
